<template>
  <div v-if="initiative" class="initiative-justification-measure tab-step-form">
    <div class="initiative-justification-measure__body">
      <div class="custom-form__block custom-form__block_cellular">
        <InputBlock
          title="Объем производства в РФ за три года"
          tooltip-text="Объем производства товаров на территории Российской Федерации за последние три года"
          :required="isCanInitiativeInput"
          :bold="!isCanInitiativeInput"
        >
          <p v-if="!isCanInitiativeInput">
            {{ getInitiative.proposedMeasureJustificationDto?.rfProductionVolumeForThreeYears ?? '-' }}
          </p>
          <ComponentInput
            v-else
            v-model="initiative.proposedMeasureJustificationDto.rfProductionVolumeForThreeYears"
            mod="rectangular"
            placeholder="Введите"
            required
          />
        </InputBlock>

        <InputBlock
          v-if="isShowQuotaVolume"
          title="Объем квоты"
          :required="isCanInitiativeInput"
          :bold="!isCanInitiativeInput"
        >
          <p v-if="!isCanInitiativeInput">{{ getInitiative.proposedMeasureJustificationDto?.quotaVolume ?? '-' }}</p>
          <ComponentInput
            v-else
            v-model="initiative.proposedMeasureJustificationDto.quotaVolume"
            mod="rectangular"
            placeholder="Введите"
            required
          />
        </InputBlock>

        <InputBlock
          title="Объем потребления за три года"
          tooltip-text="Объем потребления товаров за последние три года"
          :required="isCanInitiativeInput"
          :bold="!isCanInitiativeInput"
        >
          <p v-if="!isCanInitiativeInput">
            {{ getInitiative.proposedMeasureJustificationDto?.consumptionVolumeForThreeYears ?? '-' }}
          </p>
          <ComponentInput
            v-else
            v-model="initiative.proposedMeasureJustificationDto.consumptionVolumeForThreeYears"
            mod="rectangular"
            placeholder="Введите"
            required
          />
        </InputBlock>

        <InputBlock title="Описание товара" :required="isCanInitiativeInput" :bold="!isCanInitiativeInput">
          <p v-if="!isCanInitiativeInput">
            {{ getInitiative.proposedMeasureJustificationDto?.productDescription ?? '-' }}
          </p>
          <ComponentInput
            v-else
            v-model="initiative.proposedMeasureJustificationDto.productDescription"
            mod="rectangular no-resize"
            placeholder="Характеристика производимого товара"
            required
            type="textarea"
            :textarea-styles="{ 'min-height': '140px' }"
          />
        </InputBlock>

        <InputBlock
          title="Иностранные компании-конкуренты"
          tooltip-text="Иностранные компании, производящие аналогичный товар"
          :required="isCanInitiativeInput"
          :bold="!isCanInitiativeInput"
        >
          <p v-if="!isCanInitiativeInput">
            {{ getInitiative.proposedMeasureJustificationDto?.foreignCompetingCompanies ?? '-' }}
          </p>
          <ComponentInput
            v-else
            v-model="initiative.proposedMeasureJustificationDto.foreignCompetingCompanies"
            mod="rectangular"
            placeholder="Введите"
            required
          />
        </InputBlock>

        <InputBlock
          title="Производственные мощности в РФ за три года"
          tooltip-text="Производственные мощности предприятий на территории Российской Федерации за последние три года"
          :required="isCanInitiativeInput"
          :bold="!isCanInitiativeInput"
        >
          <p v-if="!isCanInitiativeInput">
            {{ getInitiative.proposedMeasureJustificationDto?.rfProductionFacilitiesForThreeYears ?? '-' }}
          </p>
          <ComponentInput
            v-else
            v-model="initiative.proposedMeasureJustificationDto.rfProductionFacilitiesForThreeYears"
            mod="rectangular"
            placeholder="Введите"
            required
          />
        </InputBlock>

        <InputBlock
          title="Уровень загрузки предприятия Заявителя за три года"
          :required="isCanInitiativeInput"
          :bold="!isCanInitiativeInput"
        >
          <p v-if="!isCanInitiativeInput">
            {{ getInitiative.proposedMeasureJustificationDto?.workLoadLevelForThreeYears ?? '-' }}
          </p>
          <ComponentInput
            v-else
            v-model="initiative.proposedMeasureJustificationDto.workLoadLevelForThreeYears"
            mod="rectangular"
            placeholder="Введите"
            required
          />
        </InputBlock>

        <InputBlock
          title="Доля импорта / экспорта в объеме потребления за три года"
          :required="isCanInitiativeInput"
          :bold="!isCanInitiativeInput"
        >
          <p v-if="!isCanInitiativeInput">
            {{ getInitiative.proposedMeasureJustificationDto?.consumptionShareForThreeYears ?? '-' }}
          </p>
          <ComponentInput
            v-else
            v-model="initiative.proposedMeasureJustificationDto.consumptionShareForThreeYears"
            mod="rectangular"
            placeholder="Введите"
            required
          />
        </InputBlock>

        <InputBlock
          title="Цена товара на рынке РФ за три года"
          :required="isCanInitiativeInput"
          :bold="!isCanInitiativeInput"
        >
          <p v-if="!isCanInitiativeInput">
            {{ getInitiative.proposedMeasureJustificationDto?.rfMarketPriceForThreeYears ?? '-' }}
          </p>
          <ComponentInput
            v-else
            v-model="initiative.proposedMeasureJustificationDto.rfMarketPriceForThreeYears"
            mod="rectangular"
            placeholder="Введите"
            required
          />
        </InputBlock>

        <InputBlock
          title="Средняя себестоимость единицы продукции"
          :required="isCanInitiativeInput"
          :bold="!isCanInitiativeInput"
        >
          <p v-if="!isCanInitiativeInput">
            {{ getInitiative.proposedMeasureJustificationDto?.productionUnitAverageCost ?? '-' }}
          </p>
          <ComponentInput
            v-else
            v-model="initiative.proposedMeasureJustificationDto.productionUnitAverageCost"
            mod="rectangular"
            placeholder="Введите"
            required
          />
        </InputBlock>

        <InputBlock
          title="Рентабельность производства за три года"
          :required="isCanInitiativeInput"
          :bold="!isCanInitiativeInput"
        >
          <p v-if="!isCanInitiativeInput">
            {{ getInitiative.proposedMeasureJustificationDto?.productionProfitabilityForThreeYears ?? '-' }}
          </p>
          <ComponentInput
            v-else
            v-model="initiative.proposedMeasureJustificationDto.productionProfitabilityForThreeYears"
            mod="rectangular"
            placeholder="Введите"
            required
          />
        </InputBlock>

        <InputBlock
          :title="tariffQuotaEfficiencyInfo.title"
          :tooltip-text="tariffQuotaEfficiencyInfo.tooltip"
          :required="isCanInitiativeInput"
          :bold="!isCanInitiativeInput"
        >
          <p v-if="!isCanInitiativeInput">
            {{ getInitiative.proposedMeasureJustificationDto?.tariffQuotaEfficiency ?? '-' }}
          </p>
          <ComponentInput
            v-else
            v-model="initiative.proposedMeasureJustificationDto.tariffQuotaEfficiency"
            mod="rectangular"
            placeholder="Введите"
            required
          />
        </InputBlock>

        <tepmlate>
          <InputBlock
            v-if="isShowComment"
            class="initiative-justification-measure__comment"
            title="Комментарии Заявителя по компенсации потерь для федерального бюджета"
            tooltip-text="Комментарии в части возможной компенсации потери федерального бюджета от уменьшения ставки таможенной пошлины"
            :required="isCanInitiativeInput"
            :bold="!isCanInitiativeInput"
          >
            <p v-if="!isCanInitiativeInput">
              {{ getInitiative.proposedMeasureJustificationDto?.applicantCommentOnCompensation ?? '-' }}
            </p>
            <ComponentInput
              v-else
              v-model="initiative.proposedMeasureJustificationDto.applicantCommentOnCompensation"
              mod="rectangular no-resize"
              placeholder="Введите"
              required
              type="textarea"
              :textarea-styles="{ 'min-height': '140px' }"
            />
          </InputBlock>

          <InputBlock
            v-else
            title="Основные потребители производимого товара"
            :required="isCanInitiativeInput"
            :bold="!isCanInitiativeInput"
          >
            <p v-if="!isCanInitiativeInput">
              {{ getInitiative.proposedMeasureJustificationDto?.mainConsumers ?? '-' }}
            </p>
            <ComponentInput
              v-else
              v-model="initiative.proposedMeasureJustificationDto.mainConsumers"
              mod="rectangular"
              placeholder="Введите"
              required
            />
          </InputBlock>
        </tepmlate>

        <InputBlock title="Файлы заявки" :required="isCanInitiativeInput" :bold="!isCanInitiativeInput">
          <FileMultiUploadRedesigned
            :entity="initiative"
            prop-name="requestFiles"
            @uploaded="(e) => fileUploaded(e, 'requestFiles')"
            @deleteFile="(e) => removeFile(e, 'requestFiles')"
            show-empty
            required
            :readonly="!isCanInitiativeInput"
          />
        </InputBlock>

        <InputBlock title="Файлы Excel с данными заявки" :required="isCanInitiativeInput" :bold="!isCanInitiativeInput">
          <FileMultiUploadRedesigned
            :entity="initiative"
            prop-name="dataFiles"
            @uploaded="(e) => fileUploaded(e, 'dataFiles')"
            @deleteFile="(e) => removeFile(e, 'dataFiles')"
            show-empty
            required
            :readonly="!isCanInitiativeInput"
          />
        </InputBlock>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput.vue';
  import FileMultiUploadRedesigned from '@/common/components/redesigned/FileMultiUploadRedesigned.vue';
  import InputBlock from '@/common/components/redesigned/InputBlock.vue';
  import Constants from '@/common/constants';

  import duplicateMethods from '@/modules/measure/mixins/duplicateMethods';

  import {
    INITIATIVE_REGISTRY_ACTIONS_NAME,
    INITIATIVE_REGISTRY_GETTERS_NAME,
    INITIATIVE_REGISTRY_MUTATIONS_NAME,
  } from '../../../store/initiativeRegistry/name';
  import { MEASURE_TYPE_IDS } from '../../../utils/constants';

  export default {
    name: 'InitiativeJustificationMeasure',
    mixins: [duplicateMethods],
    components: { ComponentInput, FileMultiUploadRedesigned, InputBlock },
    data() {
      return {
        initiative: null,
      };
    },
    setup: () => ({ v$: useVuelidate() }),
    computed: {
      ...mapGetters({
        getInitiative: INITIATIVE_REGISTRY_GETTERS_NAME.getInitiative,
      }),
      isShowQuotaVolume() {
        return this.getInitiative.measureTypeId === MEASURE_TYPE_IDS.TARIFF_QUOTA;
      },
      isShowComment() {
        const excludingMeasureTypeIds = [MEASURE_TYPE_IDS.INCREASE_EXPORT, MEASURE_TYPE_IDS.INCREASE_IMPORT];
        return !excludingMeasureTypeIds.includes(this.getInitiative.measureTypeId);
      },
      tariffQuotaEfficiencyInfo() {
        switch (this.getInitiative.measureTypeId) {
          case MEASURE_TYPE_IDS.INCREASE_EXPORT:
          case MEASURE_TYPE_IDS.INCREASE_IMPORT:
            return {
              title: 'Эффективность повышения таможенной пошлины',
              tooltip: 'Обоснование эффективности повышения таможенной пошлины',
            };
          case MEASURE_TYPE_IDS.DECREASE_EXPORT:
          case MEASURE_TYPE_IDS.DECREASE_IMPORT:
            return {
              title: 'Эффективность снижения таможенной пошлины',
              tooltip: 'Обоснование эффективности снижения таможенной пошлины',
            };
          case MEASURE_TYPE_IDS.TARIFF_QUOTA:
            return {
              title: 'Эффективность введения тарифной квоты',
              tooltip: 'Обоснование причины введения тарифной квоты',
            };
          default:
            return {
              title: 'Эффективность предоставления тарифной льготы',
              tooltip: 'Обоснование причины предоставления тарифной льготы',
            };
        }
      },
    },
    created() {
      this.initiative = JSON.parse(JSON.stringify(this.getInitiative));
    },
    activated() {
      if (!this.initiative.proposedMeasureJustificationDto) return;

      if (!this.isShowQuotaVolume) {
        this.initiative.proposedMeasureJustificationDto.quotaVolume = '';
      }

      if (this.isShowComment) {
        this.initiative.proposedMeasureJustificationDto.mainConsumers = '';
      } else {
        this.initiative.proposedMeasureJustificationDto.applicantCommentOnCompensation = '';
      }
    },
    deactivated() {
      this.v$.$reset();
    },
    emits: ['unlock'],
    methods: {
      ...mapActions({
        createInitiative: INITIATIVE_REGISTRY_ACTIONS_NAME.createInitiative,
        updateInitiative: INITIATIVE_REGISTRY_ACTIONS_NAME.updateInitiative,
      }),
      ...mapMutations({
        setProposedMeasureJustificationDto: INITIATIVE_REGISTRY_MUTATIONS_NAME.setProposedMeasureJustificationDto,
        setRequestFiles: INITIATIVE_REGISTRY_MUTATIONS_NAME.setRequestFiles,
        setDataFiles: INITIATIVE_REGISTRY_MUTATIONS_NAME.setDataFiles,
        resetState: INITIATIVE_REGISTRY_MUTATIONS_NAME.resetState,
      }),
      /**
       * @public
       * Вызывается родительским компонентом пошагового таба
       */
      validate() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', Constants.validationError, 'error');
          this.$emit('unlock');
          return false;
        }

        this.updateState();
        this.saveInitiative();

        return true;
      },
      updateState() {
        this.setProposedMeasureJustificationDto(this.initiative.proposedMeasureJustificationDto);
        this.setRequestFiles(this.initiative.requestFiles);
        this.setDataFiles(this.initiative.dataFiles);
      },
      async saveInitiative() {
        try {
          this.isInitiativeExist ? await this.update() : await this.add();
          this.resetState();
          this.$router.push({ name: 'RequestInitiativeTtrRegistry' });
        } catch (err) {
          Constants.alert.fire('Ошибка', Constants.validationError, 'error');
        } finally {
          this.$emit('unlock');
        }
      },
      async add() {
        const response = await this.createInitiative();
        const initiativeId = response.data.id;
        Constants.alert.fire('Сохранение', `Заявка № ${initiativeId} успешно создана.`, 'success');
      },
      async update() {
        const response = await this.updateInitiative();
        const initiativeId = response.data.id;
        Constants.alert.fire('Обновление', `Заявка № ${initiativeId} успешно обновлена.`, 'success');
      },
      fileUploaded(e, key) {
        if (this.initiative[key]) {
          this.initiative[key].push(e.data);
        }
      },
      removeFile(e, key) {
        const file = this.initiative[key].find((x) => x.id === e.id);
        const index = this.initiative[key].indexOf(file);
        if (index > -1) {
          this.initiative[key].splice(index, 1);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/custom-form.scss';

  .initiative-justification-measure {
    &__comment {
      :deep(.custom-form__label) {
        text-wrap: nowrap;

        @media (max-width: 1350px) {
          text-wrap: wrap;
        }
      }
    }
  }
</style>
