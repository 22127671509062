<template>
  <div>
    <div class="initiative-table table-container-base">
      <table>
        <thead>
          <tr>
            <th v-if="!readonly" class="th-grey column-icon"></th>
            <th class="th-grey">ТН ВЭД ЕАЭС</th>
            <th class="th-grey">Действующая ставка таможенной пошлины</th>
            <th v-if="isShowProposedDutyRate" class="th-grey">{{ proposedDutyRateHeadName }}</th>
            <th class="th-grey">Предлагаемая детализация ТН ВЭД ЕАЭС</th>
            <th class="th-grey">Предлагаемое наименование товара</th>
          </tr>
        </thead>
        <tbody v-if="localeValue.length">
          <tr v-for="(item, index) in localeValue" :key="item.tnvedId">
            <td v-if="!readonly" class="column-icon">
              <div class="wrap-icon">
                <IconComponent
                  class="icon--full-svg delete"
                  @click.prevent.stop="deleteRate(index)"
                  name="delete-not-fill"
                />
              </div>
            </td>
            <td>
              <template v-if="readonly">{{ item.tnved?.code ?? '-' }}</template>
              <SelectionGoods
                v-else
                :selectedIdsProps="item.tnvedId ? [item.tnvedId] : []"
                code-only
                :deleting="false"
                :multiple="false"
                required
                locale-state
                :codes="isConstraintCodes ? codes : []"
                @selectSelectedIDs="(id) => updateSelectedIDs(id, index)"
              />
            </td>
            <td>
              <template v-if="readonly">{{ item.customsDutyCurrentRate }}</template>
              <ComponentInput
                v-else
                v-model="item.customsDutyCurrentRate"
                mod="rectangular"
                placeholder="Введите"
                required
              />
            </td>
            <td v-if="isShowProposedDutyRate">
              <template v-if="readonly">{{ item.proposedDutyRate ?? '-' }}</template>
              <ComponentInput v-else v-model="item.proposedDutyRate" mod="rectangular" placeholder="Введите" required />
            </td>
            <td>
              <template v-if="readonly">{{ item.proposedSpecification ?? '-' }}</template>
              <ComponentInput
                v-else
                v-model="item.proposedSpecification"
                mod="rectangular"
                placeholder="Введите код товара"
              />
            </td>
            <td>
              <template v-if="readonly">{{ item.proposedTnvedName ?? '-' }}</template>
              <ComponentInput
                v-else
                v-model="item.proposedTnvedName"
                mod="rectangular"
                placeholder="Введите наименование"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="16">Нет данных.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import ComponentInput from '@/common/components/ComponentInput.vue';
  import SelectionGoods from '@/common/components/redesigned/SelectionGoods.vue';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import { INITIATIVE_REGISTRY_GETTERS_NAME } from '../../../../store/initiativeRegistry/name';
  import { MEASURE_TYPE_IDS, MOVING_DIRECTION } from '../../../../utils/constants';

  import { NSITNVED_ACTIONS_NAME } from '@/store/nsitnved';

  export default {
    name: 'InitiativeRateTable',
    components: { ComponentInput, SelectionGoods, IconComponent },
    props: {
      modelValue: {
        type: Array,
        default: () => [],
      },
      readonly: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        localeValue: [],
        codes: [],
      };
    },
    emits: ['update:modelValue'],
    created() {
      this.localeValue = this.modelValue;
      this.codes = this.generateCodes();
    },
    watch: {
      modelValue(newValue) {
        this.localeValue = newValue;
      },
      isShowProposedDutyRate(newValue) {
        if (!newValue) {
          this.localeValue.forEach((item) => (item.proposedDutyRate = ''));
          this.$emit('update:modelValue', this.localeValue);
        }
      },
      async isConstraintCodes(newValue) {
        if (newValue) {
          const filteredLocaleValue = [];

          for (const item of this.localeValue) {
            try {
              const res = await this.getNSITnvedByIDs({ ids: [item.tnvedId] });
              const nSITnved = res.data[0];

              if (!this.isConstraintCode(nSITnved)) {
                filteredLocaleValue.push(item);
              }
            } catch (err) {
              console.log(err);
            }
          }

          this.$emit('update:modelValue', filteredLocaleValue);
        }
      },
    },
    methods: {
      ...mapActions({ getNSITnvedByIDs: NSITNVED_ACTIONS_NAME.getNSITnvedByIDs }),
      updateSelectedIDs(ids, index) {
        this.localeValue[index].tnvedId = ids[0];
      },
      deleteRate(index) {
        this.localeValue = this.localeValue.filter((item, i) => i !== index);
        this.$emit('update:modelValue', this.localeValue);
      },
      generateCodes() {
        return Array.from({ length: 24 }, (_, index) => {
          return (index + 1).toString().padStart(2, '0');
        });
      },
      isConstraintCode(item) {
        return this.codes.length > 0 && !this.codes.includes(item.code.slice(0, 2));
      },
    },
    computed: {
      ...mapGetters({
        getInitiative: INITIATIVE_REGISTRY_GETTERS_NAME.getInitiative,
      }),
      proposedDutyRateHeadName() {
        switch (this.getInitiative.measureTypeId) {
          case MEASURE_TYPE_IDS.TARIFF_QUOTA:
            return 'Предлагаемая внутриквотная ставка таможенной пошлины';
          case MEASURE_TYPE_IDS.TARIFF_BENEFIT:
            return '';
          default:
            return 'Предлагаемая ставка таможенной пошлины';
        }
      },
      isShowProposedDutyRate() {
        return this.getInitiative.measureTypeId !== MEASURE_TYPE_IDS.TARIFF_BENEFIT;
      },
      isConstraintCodes() {
        return (
          this.getInitiative.measureTypeId === MEASURE_TYPE_IDS.TARIFF_QUOTA &&
          this.getInitiative.nsiTradingOperationId === MOVING_DIRECTION.IMPORT
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-base.scss';

  .initiative-table {
    box-shadow: none;

    td,
    th {
      min-width: 242px;
      max-width: 242px;
    }

    .column-icon {
      &:first-child {
        min-width: 32px;
        max-width: 32px;
        padding-left: 0;
      }
    }

    th {
      vertical-align: bottom;
      font: $fira-16-M !important;
    }

    td {
      &:first-child {
        :deep(.icon) {
          margin-left: 0;
        }
      }
    }

    .wrap-icon {
      margin: 12px 0 0 0;
    }
  }
</style>
